<template>
    <div class="bg-white rounded-xl shadow-md my-4 mx-2 md:mx-0">
        <div v-if="campaign1.length > 0">
            <div class="flex flex-row justify-between">
                <h1 class="mt-5 pl-3 flex-auto text-xl font-semibold">
                    {{campaign1Title}}
                </h1>
                <div v-if="campaign1.length >= 5">
                    <router-link :to="{name: 'campaign.program.index', params:{id: campaign1Id}}">
                        <h1 class="mt-6 px-3 text-base underline font-semibold text-yellow-500">
                            Lihat Semua
                        </h1>
                    </router-link>
                </div>
            </div>

            <!-- <div class="px-1 flex overflow-x-auto">
                <div v-for="(campaign) in campaign1" :key="campaign.id">
                    <CampaignItem :image="campaign.image" :title="campaign.title" :slug="campaign.slug"
                        :sumDonation="campaign.sum_donation" :targetDonation="campaign.target_donation"
                        :maxDate="campaign.max_date" 
                        :typeCampaign="campaign.tipe_campaign"/>
                </div>
            </div> -->
            <div class="px-5 py-1 my-4">
                <vueper-slides autoplay :arrows="true" :touchable="false" :gap="3" fixed-height="320px" class="no-shadow">
                    <vueper-slide v-for="(campaign, i) in campaign1" :key="i">
                        <template #content>
                            <router-link :to="{name: 'campaign.show', params:{slug: campaign.slug}}">
                                <div class="bg-gray-100 rounded-md h-80">
                                    <img :src="campaign.image" class="rounded-md object-contain h-80"/>
                                </div>
                            </router-link>
                        </template>
                    </vueper-slide>
                    <template #arrow-left>
                        <div class="ml-0">
                            <i class="fa fa-chevron-left fa-lg text-yellow-600" />
                        </div>
                    </template>
                    <template #arrow-right>
                        <div class="mr-0">
                            <i class="fa fa-chevron-right fa-lg text-yellow-600" />
                        </div>
                    </template>
                    <template #bullets="{ bulletIndexes, goToSlide, currentSlide }">
                        <span v-for="(slideIndex, i) in bulletIndexes" :key="i" :class="{ 'active': currentSlide === slideIndex }" @click="goToSlide(slideIndex)" style="z-index: 3">
                            <div class="h-2 bg-white rounded-md my-2 mx-1"
                                :class="{
                                    'w-2': currentSlide != slideIndex,
                                    'w-6': currentSlide == slideIndex,
                                    'bg-white': currentSlide != slideIndex,
                                    'bg-yellow-500': currentSlide == slideIndex
                                }">
                            </div>
                        </span>
                    </template>
                </vueper-slides>
            </div>

        </div>
    </div>

</template>

<script>

//vueper slider
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
// import CampaignItem from '@/components/CampaignItem.vue'

export default {
    components: {
        // CampaignItem,
        VueperSlides, 
        VueperSlide
    },
    props: {
        campaign1Title: String,
        campaign1Id: Number,
        campaign1: Array
    },
    method() {
        console.log('prog1', this.campaign1)
    }
}
</script>

<style scoped>
.vueperslides__bullet .default {
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  box-shadow: none;
  transition: 0.3s;
  width: 16px;
  height: 16px;
}

.vueperslides__bullet--active .default {background-color: #42b983;}

.vueperslides__bullet span {
  display: block;
  color: #fff;
  font-size: 10px;
  opacity: 0.8;
}
</style>
